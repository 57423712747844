@import 'src/styles/variables';

.services {
    margin: 80px auto;
    width: $main-width;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .services-title {
        margin-bottom: 20px;
        font-family: $font-merriweather;
        font-weight: 900;
        color: #{$color-dark};
        text-align: center;
    }
    .services_description {
        max-width: 700px;
        margin-bottom: 40px;
        font-family: $font-montserrat;
        font-weight: 400;
        color: #{$color-dark};
        text-align: center;
    }
    .services-cards {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-template-rows: 1fr 1fr;
        gap: 36px;
    }
}