@import 'src/styles/variables';

.survey-result {
  margin: 80px auto 0;
  width: $main-width;

  .result-title {
    margin-bottom: 36px;
    font-family: $font-merriweather;
    font-weight: 900;
    color: #{$color-dark};
    text-align: center;
  }
  .result-button-container {
    margin-top: 36px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .two-plans {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .plan {
      &:first-child {
        margin-right: 16px;
      }
      &:last-child {
        margin-left: 16px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .survey-result {
    .two-plans {
      flex-direction: column;

      .plan {
        &:first-child {
          margin-right: 0px;
          margin-bottom: 32px;
        }
        &:last-child {
          margin-left: 0px;
        }
      }
    }
  }
}
