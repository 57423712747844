@import 'src/styles/variables';

.card-container {
  position: relative;

  .service-link {
    display: flex;
    min-height: 300px;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px;
    color: $color-dark;
    box-shadow: $box-shadow;
    border-radius: 20px;

    .card-icon {
      margin-bottom: 16px;
      filter: invert(50%) sepia(1%) saturate(1428%) hue-rotate(315deg) brightness(85%) contrast(78%);
    }
    .card-title {
      margin-bottom: 12px;
      font-family: $font-montserrat;
      font-weight: 700;
      font-size: 18px;
      color: $color-primary;
    }
    .card-description,
    .card-list {
      font-family: $font-montserrat;
      font-weight: 300;
      font-size: 14px;
    }
    .card-link {
      display: flex;
      justify-content: flex-end;
      color: $color-secondary;

      .card-link-text {
        font-family: $font-montserrat;
        font-size: 13px;
        font-weight: 600;
        color: $color-secondary;
      }
      .arrow-icon {
        margin-left: 3px;
        width: 16px;
        height: 16px;
        filter: invert(75%) sepia(92%) saturate(1955%) hue-rotate(320deg) brightness(101%) contrast(103%);
      }
    }
  }
  &.unavailable-card {
    .unavailable-layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.3);
    }
    .arrow-icon {
      display: none;
    }
  }
  &.available-card {
    cursor: pointer;
    border-radius: 20px;

    .unavailable-layer {
      display: none;
    }
    &:hover {
      background-color: $color-primary;
      color: $color-light;

      .card-title {
        color: $color-light;
      }
      .card-description {
        color: $color-light;
      }
      .card-icon {
        filter: invert(93%) sepia(23%) saturate(4862%) hue-rotate(184deg) brightness(97%) contrast(102%);
      }
      .card-list {
        color: $color-light;
      }
    }
  }
}
