@import '~normalize.css';
@import 'styles/fonts';
@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/animations';

body {
  white-space: pre-line;
  margin: 0;
  font-family: $font-montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-light;
  color: $color-dark;
  font-size: 14px;
  line-height: 1.5;
}

strong {
  font-weight: 600 !important;
}

a {
  text-decoration: none;
  //color: $accent-color;
  //transition: color $t-default;
  outline: none;

  &:focus,
  &:hover,
  &:active {
    //color: $accent-color-vivid;
  }
}

h1,
h2,
h3 {
  &:first-child {
    margin-top: 0;
  }
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 20px;
}

ul,
ol {
  padding-left: 20px;

  li {
    margin-bottom: 5px;
  }
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

html body {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

#root {
  overflow-x: hidden;
  height: 100vh;
  scroll-behavior: smooth;
}

.h1-title {
  font-size: 36px !important;
  line-height: 1.2 !important;
}

.description-title {
  font-size: 20px !important;
  line-height: 1.2;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.display-none {
  display: none !important;
}

@media screen and (min-width: 451px) and (max-width: 950px) {
  .h1-title {
    font-size: 30px !important;
  }
  .description-title {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 610px) {
  .h1-title {
    font-size: 24px !important;
  }
  .description-title {
    font-size: 14px !important;
  }
}
