@import 'src/styles/variables';

.header-container {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 999;

  .header {
    margin: 0 auto;
    padding-top: 45px;
    width: $main-width;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-dark;

    .logo {
      height: 65px;
    }
    .nav-options {
      display: flex;

      .nav-option-link {
        margin: 0 30px 0 0;

        .nav-option {
          font-family: $font-montserrat;
          font-weight: 500;
          font-size: 18px;
          color: #{$color-dark};

          &:hover {
            color: #{$color-primary};
          }
        }
        &:last-child {
          margin: 0px;
        }
      }
    }
    .social-media {
      display: flex;

      .social-media-link {
        margin-right: 16px;

        .social-media-icon-container {
          width: 32px;
          height: 32px;
          background-color: $color-dark;
          background-size: cover;
          border-radius: 50%;

          .social-media-icon {
            width: 18px;
            height: 18px;
          }
        }
        .telegram-icon {
          .social-media-icon {
            margin: 7px 6px;
          }
          &:hover {
            background-color: $color-telegram;
          }
        }
        .whatsapp-icon {
          .social-media-icon {
            margin: 7px 7px;
          }
          &:hover {
            background-color: $color-whatsapp;
          }
        }
        .viber-icon {
          .social-media-icon {
            margin: 7px 7px;
          }
          &:hover {
            background-color: $color-viber;
          }
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

.MuiFab-root:hover {
  background-color: $color-primary-dark !important;
}

.MuiDrawer-paper {
  padding: 48px;

  .drawer-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .nav-options {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .nav-option-link {
        margin-bottom: 24px;

        .nav-option {
          font-family: $font-montserrat;
          font-weight: 500;
          font-size: 24px;
          color: #{$color-dark};

          &:hover {
            color: #{$color-primary};
          }
        }
        &:last-child {
          margin: 0px;
        }
      }
    }
    .social-media {
      display: flex;
      flex-direction: column;

      .social-media-link {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        .social-media-icon {
          margin-right: 8px;
        }
        .social-media-name {
          font-family: $font-montserrat;
          font-weight: 400;
          font-size: 18px;
          text-align: center;
          color: #{$color-dark};
        }
      }
    }
  }
}

.sticky-header {
  box-shadow: $box-shadow;
  background-color: $color-light;

  .header {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
