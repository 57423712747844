@import 'src/styles/variables';

.find-service {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  width: fit-content;
  margin: 0 auto;
  padding: 32px 48px;
  border: 3px solid #dbdbfc;
  border-radius: 10px;

  .find-service-title {
    margin-bottom: 24px;
    text-align: center;
    font-family: $font-montserrat;
    font-weight: 600;
    font-size: 16px;
    color: $color-dark;
  }
}
