@import 'src/styles/variables';

.survey {
  margin: 0 auto;
  padding: 60px 0 40px 0;
  display: flex;
  flex-direction: column;
  width: 80vw;

  .back-button {
    z-index: 990;
    margin-bottom: 48px;
  }
  .MuiLinearProgress-root {
    height: 8px;
    margin-bottom: 32px;
    border-radius: 8px;
  }
  .survey-title {
    margin-bottom: 56px;
    font-family: $font-merriweather;
    font-weight: 900;
    color: #{$color-dark};
    text-align: center;
  }
  .survey-answers {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 36px;
  }
}
