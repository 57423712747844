$color-primary: #4b4aef;
$color-primary-dark: #3a3ad4;
$color-secondary: #ff9458;
$olor-additional: #4b4aef33;
$color-dark: #333333;
$color-light: #ffffff;
$color-medium: rgba(51, 51, 51, 0.7);
$color-viber: #7360f2;
$color-telegram: #2aabee;
$color-whatsapp: #25d366;

$font-merriweather: 'Merriweather';
$font-montserrat: 'Montserrat';

$main-width: 80%;

$box-shadow: 5px 5px 20px rgba(165, 165, 165, 0.15);

$description-font-size: 20px;
