@import 'src/styles/variables';

.plan {
  padding: 48px 32px;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow;
  border-radius: 0px 20px;

  &.start {
    background: $color-light;
  }
  &.standart {
    background: radial-gradient(119.27% 55.73% at 74.14% 85.86%, rgba(75, 74, 239, 0.15) 0%, rgba(75, 74, 239, 0) 100%),
      radial-gradient(226.31% 39% at 33.57% 22.74%, rgba(75, 74, 239, 0.1) 0%, rgba(75, 74, 239, 0) 100%);
  }
  &.individual {
    background-color: $color-primary;

    .plan-title,
    .plan-option-text,
    .price-text {
      color: $color-light;
    }
    .checkmark-icon {
      filter: invert(100%) sepia(87%) saturate(148%) hue-rotate(245deg) brightness(113%) contrast(100%) !important;
    }
  }
  .plan-title,
  .plan-option-text,
  .price-text {
    font-family: $font-montserrat;
    color: $color-dark;
  }
  .plan-title {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 18px;
  }
  .prices {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .price-text {
      font-weight: 700;
      font-size: 26px;
    }
  }
  .b2b_plans {
    display: flex;
    flex-direction: column;

    .plan-option {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .checkmark-icon {
        width: 24px;
        height: 24px;
        margin-right: 6px;
        filter: invert(26%) sepia(88%) saturate(4875%) hue-rotate(239deg) brightness(99%) contrast(89%);
      }
      .plan-option-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 105%;
        letter-spacing: -0.02em;

        strong {
          font-weight: 400;
          color: $color-primary;
        }
      }
    }
  }
}
