@import 'src/styles/variables';

.MuiButton-root.survey-button {
  width: 100% !important;
  height: 250px;
  border-radius: 0px 20px !important;
  box-shadow: $box-shadow;
  font-size: 24px !important;
  font-weight: 600 !important;
  text-transform: unset !important;

  &.primary {
    background-color: $color-primary;
    color: $color-light;

    &:hover {
      background-color: $color-primary-dark;
    }
  }
  &.secondary {
    background: radial-gradient(
        54.1% 83.77% at 74.29% 92.46%,
        rgba(75, 74, 239, 0.18) 0%,
        rgba(75, 74, 239, 0.18) 0.01%,
        rgba(75, 74, 239, 0.06) 100%
      ),
      radial-gradient(88.01% 105.41% at 21.43% 13.77%, rgba(75, 74, 239, 0.1) 0%, rgba(75, 74, 239, 0.04) 93.24%);
  }
}

@media screen and (max-width: 600px) {
  .MuiButton-root.survey-button {
    height: 100px;
  }
}
