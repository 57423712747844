@import 'src/styles/variables';

.discount-container {
  position: relative;

  .discount {
    max-width: 80%;
    width: 100%;
    margin: 0 auto;
    padding: 32px 48px;
    box-shadow: $box-shadow;
    border-radius: 10px;
    background-color: $color-dark;
    background-image: url('../../../assets/images/kids.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 500px;

    .discount-title {
      width: 100%;
      margin-bottom: 24px;
      font-family: $font-merriweather;
      font-weight: 900;
      color: $color-dark;
      text-align: center;
    }
    .discount-info {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .discount-description {
        width: 90%;
        font-family: $font-merriweather;
        font-weight: 600;
        color: $color-light;
        text-align: center;
      }
    }
  }
  .gradient {
    position: absolute;
    width: 80vw;
    left: -50%;
    top: -90%;
    z-index: -1;
    opacity: 0.5;
  }
}

@media screen and (max-width: 500px) {
  .discount-container {
    .gradient {
      width: 150vw;
      left: -40%;
      top: -10%;
    }
    .discount {
      height: 350px;
      padding: 16px 24px;
    }
  }
}
