@import 'src/styles/variables';

.main-container {
  position: relative;
  margin: 80px auto;
  width: $main-width;
  display: flex;
  flex-direction: column;

  .main {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .main-left {
      display: flex;
      flex-direction: column;
      width: 60%;
      color: #{$color-dark};

      .main-title {
        font-family: $font-merriweather;
        font-weight: 900;
      }
      .main-description {
        margin-top: 32px;
        font-family: $font-montserrat;
        font-weight: 400;
      }
    }
    .main-right {
      height: 450px;
    }
  }
  .button-container {
    margin-top: 50px;
    display: flex;

    .button {
      margin-right: 24px;
    }
  }
  .gradient {
    position: absolute;
    width: 80vw;
    right: -35%;
    top: -30%;
    z-index: -1;
  }
}

@media screen and (min-width: 850px) and (max-width: 950px) {
  .main-container .main .main-right {
    height: 400px;
  }
}

@media screen and (min-width: 791px) and (max-width: 849px) {
  .main-container .main .main-right {
    height: 350px;
  }
}

@media screen and (max-width: 790px) {
  .main-container .button-container {
    margin-top: 32px;

    .button {
      height: 50px;
    }
  }
  .main-container .main .main-right {
    display: none;
  }
  .main-container .main .main-left {
    width: 100%;
  }
}

@media screen and (max-width: 560px) {
  .main-container {
    .button-container {
      flex-direction: column;

      .button {
        max-width: 160px;
        margin-right: 0px;
        margin-bottom: 24px;
      }
      .how-it-works {
        display: none;
      }
    }
    .gradient {
      width: 150vw;
      right: -90%;
      top: -60%;
    }
  }
}
