@import 'src/styles/variables';

.legalization-container {
  width: 100vw;
  margin: 220px auto;
  background: $color-primary;

  .legalization {
    margin: 0 auto;
    padding: 116px 0;
    width: $main-width;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $color-light;

    .legalization-title {
      margin-top: 40px;
      max-width: 900px;
      font-family: $font-merriweather;
      font-weight: 900;

      strong {
        font-weight: 900 !important;
        color: $color-secondary;
      }
    }
    .legalization-description {
      margin-top: 32px;
      margin-bottom: 60px;
      max-width: 1000px;
      font-family: $font-montserrat;
      font-weight: 300;
    }
    .legalization-button {
      background-color: white !important;
    }
  }
}

@media screen and (max-width: 700px) {
  .legalization-container {
    margin: 20px auto;

    .legalization {
      padding: 76px 0;
    }
  }
}
