@import 'src/styles/variables';

.tooltip-text {
  display: inline-block;
  width: min-content;

  span {
    width: max-content;
    display: inline-block;
    text-decoration: underline;
  }
  &:hover {
    span {
      color: $color-secondary;
      font-weight: 600;
      text-decoration: none;
    }
  }
}
