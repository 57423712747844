@import 'src/styles/variables';

.additional {
  position: relative;
  margin: 220px auto;
  width: $main-width;
  display: flex;
  padding: 48px 70px;
  flex-direction: column;
  align-items: center;
  box-shadow: $box-shadow;
  border-radius: 20px;
  background-color: $color-light;

  .vat-select {
    margin-left: auto;
  }
  .additional-title {
    margin-bottom: 32px;
    font-family: $font-merriweather;
    font-weight: 900;
    text-align: center;
    color: #{$color-dark};
  }
  .additional-option {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .additional-option-text {
      width: 100%;
      margin: 22px 0;
      display: flex;
      align-items: flex-end;

      .additional-option-title {
        max-width: fit-content;
        margin-right: 24px;
        font-family: $font-montserrat;
        font-weight: 500;
        font-size: 18px;
        color: #{$color-dark};
      }
      .additional-option-description {
        font-family: $font-montserrat;
        font-weight: 500;
        font-size: 12px;
        color: $color-medium;
      }
    }
    .additional-option-price {
      min-width: fit-content;
      font-family: $font-montserrat;
      font-weight: 500;
      font-size: 20px;
      text-align: right;
      color: #{$color-primary};
    }
  }
  .divider {
    width: 105%;
    height: 0.5px;
    background-color: $color-medium;
  }
  .button-container {
    margin-top: 32px;
  }
  .gradient {
    position: absolute;
    width: 80vw;
    right: -60%;
    bottom: 0;
    z-index: -1;
    opacity: 0.7;
  }
}

@media screen and (max-width: 700px) {
  .additional-option {
    flex-direction: column;

    .additional-option-title {
      max-width: min-content;
    }
    .additional-option-price {
      margin-bottom: 10px !important;
      width: 100%;
    }
  }
}

@media screen and (max-width: 514px) {
  .additional-option-text {
    flex-direction: column;
    align-items: flex-start !important;

    .additional-option-description {
      margin-top: 12px;
    }
  }
}

@media screen and (max-width: 700px) {
  .additional {
    margin: 20px auto;

    .gradient {
      bottom: 50%;
      width: 250vw;
    }
  }
}

@media screen and (max-width: 1180px) {
  .additional {
    .additional-option {
      .additional-option-text {
        .additional-option-title {
          &.has-description {
            max-width: 230px;
          }
        }
      }
    }
  }
}
