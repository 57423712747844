@import 'src/styles/variables';

.MuiDialog-paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  border-radius: 20px !important;

  .popup-title {
    font-family: $font-merriweather;
    font-weight: 900;
    text-align: center;
    color: #{$color-dark};
  }
  .popup-description {
    margin-top: 16px;
    font-family: $font-montserrat;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #{$color-dark};
  }
  .socials-container {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, min-content));
    margin-top: 24px;
    justify-content: space-evenly;

    .social-media-link {
      display: flex;
      align-items: center;
      width: min-content;

      .social-media-icon-container {
        margin-right: 12px;
        width: 30px;
        height: 30px;
        background-color: $color-medium;
        background-size: cover;
        border-radius: 50%;

        .social-media-icon {
          width: 16px;
          height: 16px;
        }
        .telegram-icon {
          margin: 7px 6px;
        }
        .whatsapp-icon {
          margin: 7px 7px;
        }
        .viber-icon {
          margin: 7px 7px;
        }
      }
      .social-media-name {
        color: $color-medium;
      }
      &:last-child {
        margin-right: 0px;
      }
      &:hover {
        .social-media-icon-container {
          background-color: $color-primary;
        }
        .social-media-name {
          color: $color-primary;
        }
      }
    }
  }
  .contacts-container {
    margin-top: 48px;
    padding: 32px 48px;
    border: 3px solid #dbdbfc;
    border-radius: 10px;

    .contacts-title {
      font-family: $font-montserrat;
      font-weight: 400;
      font-size: 18px;
      color: #{$color-dark};
      text-align: center;
    }
    .contacts {
      display: flex;
      justify-content: space-between;

      .contacts-text {
        margin-top: 12px;
        font-family: $font-montserrat;
        font-weight: 500;
        font-size: 18px;
        color: #{$color-dark};
      }
    }
  }
}

@media screen and (max-width: 593px) {
  .MuiDialog-paper {
    padding: 32px;

    .popup-description {
      margin-top: 8px;
    }
    .socials-container {
      margin-top: 16px;

      .social-media-link {
        margin-bottom: 16px;
        margin-right: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .contacts-container {
      margin-top: 16px;
      padding: 16px;
    }
    .contacts {
      flex-direction: column;
      align-items: center;
    }
  }
}
