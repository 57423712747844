@import 'src/styles/variables';

.plans {
  position: relative;
  margin: 80px auto;
  width: $main-width;
  display: flex;
  flex-direction: column;
  align-items: center;

  .plans-title {
    max-width: 800px;
    margin-bottom: 24px;
    font-family: $font-merriweather;
    font-weight: 900;
    color: #{$color-dark};
    text-align: center;
  }
  .plans-description {
    max-width: 80%;
    margin-bottom: 50px;
    font-family: $font-montserrat;
    font-weight: 400;
    color: #{$color-dark};
    text-align: center;
  }
  .b2b-plans {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3%;
  }
  .slick-slider {
    width: 100vw;

    .slick-track {
      display: flex !important;
    }
    .slick-slide {
      padding: 40px;
      height: inherit !important;

      div:first-child {
        height: 100%;
      }
    }
    .slick-next,
    .slick-prev {
      z-index: 99;
      height: 40px;
      width: 40px;
    }
    .slick-next:before,
    .slick-prev:before {
      font-size: 32px;
      opacity: 1;
      color: $color-primary;
    }
    .slick-next {
      right: 0;
    }
    .slick-prev {
      left: 0;
    }
  }
  .actions {
    margin-top: 64px;
    display: flex;
    align-items: center;

    .b2b-link {
      margin-left: 56px;
      font-family: $font-montserrat;
      color: $color-primary;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .gradient {
    position: absolute;
    width: 80vw;
    left: -50%;
    top: -60%;
    z-index: -1;
    opacity: 0.7;
  }
}

@media screen and (max-width: 1100px) {
  .plans .gradient {
    top: -30%;
  }
}

@media screen and (min-width: 900px) and (max-width: 1000px) {
  .plans {
    width: 95vw;
  }
}

@media screen and (max-width: 800px) {
  .plans .gradient {
    left: -40%;
    top: -10%;
  }
}

@media screen and (max-width: 650px) {
  .actions {
    flex-direction: column;
  }
  .b2b-link {
    margin: 20px 0 0 0 !important;
  }
  .plans .gradient {
    width: 140vw;
    left: -50%;
    top: 10%;
  }
}

@media screen and (max-width: 440px) {
  .plans .gradient {
    width: 180vw;
    top: 20%;
  }
}
