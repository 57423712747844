@import 'variables';

@font-face {
    font-family: #{$font-montserrat};
    font-weight: 100;
    src: local('Montserrat'), url(/assets/fonts/Montserrat/static/Montserrat-Thin.ttf) format('truetype');
}

@font-face {
    font-family: #{$font-montserrat};
    font-weight: 200;
    src: local('Montserrat'), url(/assets/fonts/Montserrat/static/Montserrat-ExtraLight.ttf) format('truetype');
}

@font-face {
    font-family: #{$font-montserrat};
    font-weight: 300;
    src: local('Montserrat'), url(/assets/fonts/Montserrat/static/Montserrat-Light.ttf) format('truetype');
}

@font-face {
    font-family: #{$font-montserrat};
    font-weight: 400;
    src: local('Montserrat'), url(/assets/fonts/Montserrat/static/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
    font-family: #{$font-montserrat};
    font-weight: 500;
    src: local('Montserrat'), url(/assets/fonts/Montserrat/static/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
    font-family: #{$font-montserrat};
    font-weight: 600;
    src: local('Montserrat'), url(/assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: #{$font-montserrat};
    font-weight: 700;
    src: local('Montserrat'), url(/assets/fonts/Montserrat/static/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
    font-family: #{$font-montserrat};
    font-weight: 800;
    src: local('Montserrat'), url(/assets/fonts/Montserrat/static/Montserrat-ExtraBold.ttf) format('truetype');
}

@font-face {
    font-family: #{$font-montserrat};
    font-weight: 900;
    src: local('Montserrat'), url(/assets/fonts/Montserrat/static/Montserrat-Black.ttf) format('truetype');
}
  
@font-face {
    font-family: #{$font-merriweather};
    font-weight: 900;
    src: local('Merriweather'), url(/assets/fonts/Merriweather/Merriweather-Black.ttf) format('truetype');
}

@font-face {
    font-family: #{$font-merriweather};
    font-weight: 700;
    src: local('Merriweather'), url(/assets/fonts/Merriweather/Merriweather-Bold.ttf) format('truetype');
}

@font-face {
    font-family: #{$font-merriweather};
    font-weight: 400;
    src: local('Merriweather'), url(/assets/fonts/Merriweather/Merriweather-Regular.ttf) format('truetype');
}

@font-face {
    font-family: #{$font-merriweather};
    font-weight: 300;
    src: local('Merriweather'), url(/assets/fonts/Merriweather/Merriweather-Light.ttf) format('truetype');
}