@import 'src/styles/variables';

.footer-container {
  width: 100vw;
  margin: 80px 0 0 0;
  background: url('../../assets/images/footer-background.png') no-repeat;
  background-size: cover;

  .footer {
    margin: 0 auto;
    padding: 32px 0;
    width: $main-width;

    .logo-socials-container {
      display: flex;
      justify-content: space-between;

      .logo {
        height: 65px;
      }
      .social-media {
        display: flex;

        .social-media-link {
          margin-right: 16px;

          .social-media-icon {
            height: 32px;
            width: 32px;
          }

          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
    .nav-options {
      margin: 40px 0 53px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .nav-option {
        font-family: $font-montserrat;
        font-weight: 600;
        font-size: 24px;
        color: $color-dark;
        cursor: pointer;
      }
    }
    .contacts-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .contacts-text {
        font-family: $font-montserrat;
        font-weight: 400;
        font-size: 18px;
        color: $color-dark;
      }
    }
  }
  .divider {
    width: 100%;
    height: 0.5px;
    background-color: $color-medium;
  }
  .rights-text {
    margin-top: 24px;
    padding-bottom: 24px;
    font-family: $font-montserrat;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: $color-medium;
  }
}

@media screen and (max-width: 780px) {
  .nav-options {
    flex-direction: column;
    align-items: center;

    .nav-option-link {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .contacts-container {
    flex-direction: column;

    .email-link {
      margin-bottom: 8px;
    }
  }
}

@media screen and (max-width: 500px) {
  .footer-container {
    .rights-text {
      padding-bottom: 100px;
    }
  }
}
