@import 'src/styles/variables';

.goals-container {
  position: relative;
  margin: 220px auto;
  width: $main-width;
  display: flex;
  flex-direction: column;
  align-items: center;

  .goals-title {
    font-family: $font-merriweather;
    font-weight: 900;
    color: #{$color-dark};
  }

  .goals {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .goals-left {
      display: flex;
      flex-direction: column;
      width: 55%;
      color: #{$color-dark};

      .goals-description {
        margin-top: 32px;
        font-family: $font-montserrat;
        font-weight: 400;
      }
    }
    .goals-right {
      height: 550px;
    }
  }
  .gradient {
    position: absolute;
    width: 80vw;
    left: -50%;
    top: 0;
    z-index: -1;
    opacity: 0.7;
  }
}

@media screen and (min-width: 951px) and (max-width: 1030px) {
  .goals-container .goals-title {
    margin-bottom: 16px;
  }
  .goals-container .goals .goals-right {
    height: 500px;
  }
}

@media screen and (max-width: 950px) {
  .goals-container .goals .goals-right {
    height: 450px;
  }
}

@media screen and (max-width: 850px) {
  .goals-container .goals .goals-right {
    position: absolute;
    right: 0;
    top: 130px;
  }
  .goals-container .goals .goals-left {
    margin-top: 32px;
    padding: 16px;
    width: 70%;
    background-color: #ffffff;
    z-index: 99;
    box-shadow: $box-shadow;
  }
}

@media screen and (max-width: 700px) {
  .goals-container {
    margin: 20px auto;
  }
  .goals-container .goals .goals-right {
    position: absolute;
    right: 0;
    top: 150px;
  }
}

@media screen and (min-width: 501px) and (max-width: 555px) {
  .goals-container .goals .goals-right {
    position: absolute;
    right: 0;
    top: 130px;
  }
  .goals-container .goals .goals-left {
    margin-top: 70px;
    .goals-description {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 500px) {
  .goals-container {
    .goals {
      .goals-right {
        display: none;
      }
      .goals-left {
        width: 100%;
      }
      .goals-left .goals-description {
        margin-top: 0;
      }
    }
    .gradient {
      width: 150vw;
      left: -40%;
      top: -10%;
    }
  }
}
